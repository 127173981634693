import React, { FC } from "react";
import { BsChevronDown } from "react-icons/bs";
import Image from "next/image";

const footerLinkNewData = [
    {
        title: "column1",
        columnItems: [
            {
                header: "Company",
                items: [
                    {
                        name: "Partner with us",
                        externalLink: "/gallabox-partner-program",
                        isNew: true,
                    },
                    {
                        name: "Pricing",
                        link: "/pricing",
                    },
                    {
                        name: "About",
                        link: "/about",
                    },
                    {
                        name: "Careers",
                        externalLink: "https://angel.co/company/gallabox",
                    },

                    {
                        name: "Contact Us",
                        link: "/contact",
                    },
                    {
                        name: "Book a demo",
                        showForm: true,
                    },
                ],
            },
            {
                header: "Compare",
                items: [
                    {
                        name: "Gallabox vs AiSensy",
                        link: "/best-aisensy-alternative",
                        isNew: true,
                    },
                    {
                        name: "Gallabox vs Rasayel",
                        link: "/best-rasayel-alternative",
                        isNew: true,
                    },
                    {
                        name: "Gallabox vs Wati",
                        link: "/best-wati-alternative",
                    },
                    {
                        name: "Gallabox vs Zoko",
                        link: "/zoko-alternative",
                    },
                    {
                        name: "Gallabox vs Verloop",
                        link: "/verloop-alternative",
                    },
                    {
                        name: "Gallabox vs Interakt",
                        link: "/interakt-alternative",
                    },
                    {
                        name: "Gallabox vs Infobip",
                        link: "/infobip-alternative",
                    },
                    {
                        name: "Gallabox vs GetChat",
                        link: "/best-getchat-alternative",
                    },
                ],
            },
            {
                header: "Learn",
                items: [
                    {
                        name: "Whatsapp Marketing in 2024",
                        link: "/blog/whatsapp-marketing-for-businesses-in-2024-how-to-get-started",
                    },
                    {
                        name: "Effective WhatsApp Ads",
                        link: "/blog/whatsapp-ads",
                    },
                    {
                        name: "Create WhatsApp Chatbot",
                        link: "/blog/create-whatsapp-chatbot",
                    },
                    {
                        name: "What is WhatsApp Flows?",
                        link: "/blog/what-is-whatsapp-flows",
                    },
                    {
                        name: "WhatsApp Broadcasting Tips",
                        link: "/blog/whatsapp-broadcast",
                    },
                ],
            },
        ],
    },
    {
        title: "column2",
        columnItems: [
            {
                header: "Product",
                items: [
                    {
                        name: "AI WhatsApp Template Generator",
                        link: "/ai-whatsapp-template-generator",
                        isNew: true,
                    },
                    {
                        name: "WhatsApp Pricing Calculator",
                        link: "/whatsapp-conversation-pricing-calculator",
                        isNew: true,
                    },
                    {
                        name: "WhatsApp Business Solution",
                        link: "/whatsapp-business-api",
                    },
                    {
                        name: "WhatsApp Chat Widget",
                        link: "/whatsapp-chat-button",
                    },
                    {
                        name: "WhatsApp Link Generator",
                        link: "/whatsapp-link-generator",
                    },
                    {
                        name: "WhatsApp QR Code Generator",
                        link: "/whatsapp-qr-code-generator",
                    },
                    {
                        name: "WhatsApp Chatbot Template",
                        link: "/whatsapp-chatbot-templates",
                    },
                ],
            },
            {
                header: "Features",
                items: [
                    {
                        name: "WhatsApp Flows",
                        link: "/whatsapp-flows",
                        isNew: true,
                    },
                    {
                        name: "WhatsApp AI Chatbot",
                        link: "/whatsapp-ai-chatbot",
                        isNew: true,
                    },
                    {
                        name: "WhatsApp Shop",
                        link: "/build-your-shop-on-whatsapp",
                    },
                    {
                        name: "WhatsApp Business API",
                        link: "/whatsapp-business-api",
                    },

                    {
                        name: "WhatsApp Chatbot",
                        link: "/whatsapp-chatbot",
                    },
                    {
                        name: "WhatsApp Broadcast",
                        link: "/whatsapp-broadcast",
                    },
                    {
                        name: "Click to WhatsApp Ads",
                        link: "/click-to-whatsapp-ads",
                    },

                    {
                        name: "Shared Team Inbox",
                        link: "/whatsapp-shared-inbox",
                    },
                    {
                        name: "Drip Marketing",
                        link: "/whatsapp-drip-marketing",
                    },
                    {
                        name: "WhatsApp Payments",
                        link: "/whatsapp-payments",
                    },
                ],
            },
        ],
    },
    {
        title: "Column3",
        columnItems: [
            {
                header: "Industry",
                items: [
                    {
                        name: "Education",
                        link: "/whatsapp-chatbot-for-edutech",
                    },
                    {
                        name: "Travel",
                        link: "/whatsapp-chatbot-for-travel-and-tourism",
                    },
                    {
                        name: "Real Estate",
                        link: "/whatsapp-chatbot-for-real-estate",
                        isNew: true,
                    },
                    {
                        name: "BFSI",
                        link: "/banking-chatbots",
                    },
                    {
                        name: "Ecommerce",
                        link: "/whatsapp-integrations/ecommerce",
                    },
                    {
                        name: "Healthcare",
                        link: "/whatsapp-chatbot-for-health-wellness-brands",
                    },
                ],
            },
            {
                header: "Integration",
                items: [
                    {
                        name: "Calendly",
                        link: "/whatsapp-integrations/calendly",
                        isNew: true,
                    },
                    {
                        name: "Zoho Books",
                        link: "/whatsapp-integrations/zoho-books",
                        isNew: true,
                    },
                    {
                        name: "LeadSquared",
                        link: "/whatsapp-integrations/lead-squared",
                    },
                    {
                        name: "Odoo",
                        link: "/whatsapp-integrations/odoo",
                        isNew: true,
                    },
                    {
                        name: "MoEngage",
                        link: "/whatsapp-integrations/moengage",
                    },
                    {
                        name: "Shopify",
                        link: "/whatsapp-integrations/shopify",
                    },
                    {
                        name: "Woocommerce",
                        link: "/whatsapp-integrations/woocommerce",
                    },
                    {
                        name: "Zoho",
                        link: "/whatsapp-integrations/zoho",
                    },
                    {
                        name: "Facebook Lead Ads",
                        link: "/whatsapp-integrations/facebook-leads-to-whatsapp",
                    },
                    {
                        name: "Zapier",
                        link: "/whatsapp-integrations/zapier",
                    },
                    {
                        name: "Hubspot",
                        link: "/whatsapp-integrations/hubspot",
                    },
                    {
                        name: "RazorPay",
                        link: "/whatsapp-integrations/razorpay",
                    },
                    {
                        name: "Google Sheets",
                        link: "/whatsapp-integrations/google-sheets",
                    },
                ],
                goToNextColumn: true,
            },
        ],
    },
    {
        title: "column4",
        columnItems: [
            {
                header: "Resources",
                items: [
                    {
                        name: "Docs",
                        externalLink: "https://docs.gallabox.com/",
                    },
                    {
                        name: "Developer APIs",
                        externalLink: "https://documenter.getpostman.com/view/17087523/Tzz7QJTJ",
                    },
                    {
                        name: "Blog",
                        link: "/blog",
                    },
                    {
                        name: "Videos",
                        externalLink: "https://www.youtube.com/c/Gallabox/videos",
                    },
                    {
                        name: "Case Study",
                        link: "/case-study",
                    },
                    {
                        name: "How-To Guides",
                        externalLink: "https://guides.gallabox.com/",
                    },
                ],
            },
            {
                header: "Support",
                items: [
                    {
                        name: "FAQs",
                        externalLink: "https://docs.gallabox.com/?q=",
                    },
                    {
                        name: "Raise a ticket",
                        externalLink: "https://gallaboxsupport.zohodesk.in/portal/en/home",
                    },
                    {
                        name: "Cookie Policy",
                        link: "/cookie-policy",
                    },
                    {
                        name: "Privacy Policy",
                        link: "/privacy-policy",
                    },
                    {
                        name: "Terms & Conditions",
                        link: "/terms",
                    },
                ],
            },
        ],
    },
];

interface MobileFooterProps {
    handleFooterLinkClick: (data: any) => void;
}

const MobileFooter: FC<MobileFooterProps> = (props) => {
    const { handleFooterLinkClick } = props;
    return (
        <footer className="lg:hidden text-gray-600 w-full mx-auto flex flex-col">
            <div className="wave-bottom py-7 flex flex-col w-full">
                <div className="flex flex-col w-auto flex-1 justify-end px-6 mb-4">
                    {footerLinkNewData.map((footerData) => (
                        <React.Fragment key={footerData.title}>
                            {footerData.columnItems.map((columnInstance: any) => (
                                <div key={columnInstance.header} className="tab w-full overflow-hidden border-b border-solid border-gray-100 mb-2.5">
                                    <input className="absolute hidden" id={`${columnInstance.header}-menu`} type="checkbox" name="Company" />
                                    <label htmlFor={`${columnInstance.header}-menu`} className="flex w-full leading-normal text-base cursor-pointer items-center">
                                        <h6 className="flex-1 m-0 capitalize font-bold tracking-wider text-lg" style={{ color: "#283560" }}>
                                            {columnInstance.header}
                                        </h6>
                                        <div className="expand-toggle-icon rounded-full flex items-center justify-center w-8 h-8">
                                            <BsChevronDown />
                                        </div>
                                    </label>
                                    <div className="tab-content overflow-hidden">
                                        <div className="flex flex-col gap-2 w-full text-inherit text-sm pb-4">
                                            {columnInstance.items.map((linkData: any) => (
                                                <a key={linkData.name} className="text-inherit cursor-pointer leading-relaxed" onClick={() => handleFooterLinkClick(linkData)}>
                                                    {linkData.name}
                                                    {linkData.isNew && <span className="new-tag">New</span>}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>

            <div className="relative flex flex-col items-center justify-center gap-3 py-4">
                <span className="text-gray-400 text-xs tracking-wide text-center">© {new Date().getFullYear()} Gallabox India Private Limited. All rights reserved.</span>
                <Image loading="lazy" src={"https://assets.gallabox.com/gb-home/footer_robot.gif"} height={66} width={66} alt="robot gif" />
            </div>

            <style jsx>{`
                .wave-bottom {
                    border-bottom: 1px solid #e5e5e5;
                }

                .new-tag {
                    background-color: #25d366;
                    color: white;
                    font-size: 0.7em;
                    padding: 0.2em 0.5em;
                    border-radius: 3px;
                    margin-left: 5px;
                }

                .tab-content {
                    padding-top: 0.625rem;
                    max-height: 0.625rem;

                    -webkit-transition: all 200ms ease-in;
                    -o-transition: all 200ms ease-in;
                    transition: all 200ms ease-in;
                }

                .tab input:checked ~ .tab-content {
                    max-height: 100vh;
                }

                .tab input + label {
                    transition: all 200ms ease-in;
                    font-weight: 400;
                }

                .tab input + label:hover {
                    color: #333333;
                }

                .tab input + label > .expand-toggle-icon {
                    transition: all 200ms ease-in;
                    color: #3364fa;
                }

                .tab input:checked + label {
                    color: #000;
                    font-weight: 500;
                }

                .tab input:checked + label > .expand-toggle-icon {
                    background-color: #ececec;
                    transform: rotate(-180deg);
                }
            `}</style>
        </footer>
    );
};

export default React.memo(MobileFooter);
